body {
    margin: 0;
  }
  
  div.screen {
    position: absolute;
    width: 1280px;
    height: 720px;
  }
  
  div.screen div.component{
    position: absolute;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }

p.alertTitle {
      margin: 10px;
  }
  
p.alertDescription {
      margin: 10px;
      padding-bottom: 20px;
  }

span.routeListItem
{
      white-space: nowrap;
      display: inline-block;
      padding: 5px;
}

TABLE.routeTable
{
    border-collapse: separate;
    width: 100%;
    border-spacing: 0;
}

/*TABLE.routeTable TD
{
  padding: 0;
}*/


TD.routeTableTime
{
    white-space: nowrap;
    padding-right: 80px;
}


TD.routeTableStation
{
    padding-left: 30px;
    width: 100%;
}

TD.routeTableImage
{
    background-image: url("https://caf.idevelopment.hu/img/static/route_line.png");
    vertical-align: middle;
    text-align: center;
    width: 120px;
    padding: 0;
  }

TD.routeTableImageLast
{
    background-image: url("https://caf.idevelopment.hu/img/static/route_line_end.png");
    vertical-align: middle;
    text-align: center;
    background-repeat: no-repeat;
    width: 120px;
    padding: 0;
}

table.transferTable {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
}

TD.transferTableStationPart
{
    white-space: nowrap;
}

TD.transferTableItem
{
    white-space: nowrap;
    padding-right: 20px;
}
td.transferTableItemDestination{
  white-space: nowrap;
  padding-right: 20px;
  width: 100%;
}

.gridContainer {
  display: grid;
  align-items: stretch;
}

.gridContainer div{
  white-space: nowrap;
}

.gridContainer div.time
{
  display: grid;
  padding-right: 80px;
  align-items: center;
}

.gridContainer div.station
{
  display: grid;
  padding-left: 30px;
}

.gridContainer div.routeTableImage
{
    display: grid;
    background-image: url("https://caf.idevelopment.hu/img/static/route_line.png");
    width: 120px;
    height: 100%;
    padding: 0;
  }

.gridContainer div.routeTableImageLast
{
    display: grid;
    background-image: url("https://caf.idevelopment.hu/img/static/route_line_end.png");
    background-repeat: no-repeat;
    width: 120px;
    height: 100%;
    padding: 0;
  }

.gridContainer div.routeTableImage img, .gridContainer div.routeTableImageLast img{
  align-self: center;
}